.App {
  /* text-align: center; */
  height: 100%;
}

.col-sm {
  width: 180px;
}

.marker-btn {
  border: 0px;
  background-color: transparent;
}

.mapboxgl-marker ul {
  opacity: 1 !important;
  margin-left: 20px;
  margin-top: 80px;
}


.minorBadge  {
  background-color: lightblue !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  color: white;
}

.lowBadge {
  background-color: skyblue !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  color: white;
}

.mediumBadeg {
  background-color: royalblue !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  color: white;
}

.highBadge {
  background-color: rgb(6, 6, 167) !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  color: white;
}

.criticalBadge {
  background-color: darkblue !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  color: white;
}

.footerElements {
  display: flex;
  margin-bottom: 30px;
  gap: 30px;
}

.footerSecond {
  margin-left: 2rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.map-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.accordion-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 360px) and (max-width: 1020px) {
  .footerElements {
    display: block;
  }

  .footerSecond {
    margin-left: 0;
  }
}
