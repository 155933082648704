.container{
    display: flex;
    gap: 40px;
    height: 30%;
}


.rightSec{
    width: 50%;
    display: flex;
    gap: 10px;
}

.rightSec p{
    font-size: 8px;
    color: rgb(75, 75, 75);
    margin-top: 10px;
}



.leftSec{
    width: 50%;
}

.leftRow{
    display: flex;
    margin-bottom: 50px;
}

.spanElement{
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50px ;
    padding: 4px;
    border-left: 10px #6495ed solid;
    background-color: white;
}

.spanElement img{
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 50% 50%;
    
}

.spanElement h6{
    font-size: 11px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: .5px dashed grey;
    margin-top: 5px;
}

.spanElement p{
    font-size: 8px;
    margin: 0;
    line-height: 9px;
    width: 93px;
}